import React from 'react';
import { Link } from 'gatsby';

export const ResourcePreview = ({ resource }) => {
  const { title, slug, heroImage } = resource;
  return (
    <li
      key={slug}
      className="category-block"
      style={{
        backgroundImage: `url(${heroImage.fluid.src})`,
      }}
    >
      <Link to={`/resources/${slug}`}>{title}</Link>
    </li>
  );
};
