import React from 'react';

export const AdUnit = ({ ad, classString }) => (
  <a href={ad.link} target="_blank" rel="noreferrer" className={classString}>
    <img
      src={ad.asset?.file?.url}
      width={ad.asset?.file?.details?.image?.width}
      height={ad.asset?.file?.details?.image?.height}
      alt={ad.asset?.title}
    />
  </a>
);
