import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { AdUnit } from '../components/adunit';
import { ResourcePreview } from '../components/resource-preview';

export default ({ data }) => {
  const {
    resources: { resources, adUnits },
  } = data;

  return (
    <Layout mainClass="resources">
      <div className="section-header">
        <div className="section-header-inner">
          <h1 className="section-title">Resources</h1>
        </div>
      </div>
      <div className="article-page">
        <ol
          className={`article article--resources ${adUnits ? '' : 'no-border'}`}
        >
          {resources.map(resource => (
            <ResourcePreview resource={resource} />
          ))}
        </ol>
        <div className="sidebar">
          <div className="sidebar-inner">
            {adUnits && adUnits.map(ad => <AdUnit key={ad.id} ad={ad} />)}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ResourcePage {
    resources: contentfulResourcesPage {
      id
      title
      resources {
        ... on ContentfulResource {
          id
          slug
          title
          heroImage {
            fluid(maxWidth: 400, resizingBehavior: FILL) {
              src
            }
          }
        }
      }
      adUnits {
        ...AdUnitFragment
      }
    }
  }
`;
